import React, { useEffect, useState } from "react";
import "./posts.css";
import { Post } from "./post";

const base = process.env.REACT_APP_BASE;

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      fetch(base + "/post")
        .then((res) => res.json())
        .then((data) => setPosts(data));
    };

    getPosts();
  }, []);

  return (
    <div className="posts">
      <h1>Posts</h1>
      {posts.length ? (
        posts.map((post) => <Post post={post} key={post.id} />)
      ) : (
        <h2 className="loading">Loading ...</h2>
      )}
    </div>
  );
};

export default Posts;
