import React, { useState } from "react";
import "./form.css";

const base = process.env.REACT_APP_BASE;

const Form = () => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const data = {
      username: name,
      title: title,
      content: content,
      image: selectedFile,
    };
    fetch(base + "/post", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        const { success, msg } = data;
        alert(msg);
        if (success) {
          window.location.reload();
        }
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Your Post</h2>
      <label>
        Name:
        <input
          type="text"
          value={name}
          placeholder="Your preferred name here."
          onChange={(e) => setName(e.target.value)}
        />
      </label>
      <label>
        Title:
        <input
          type="text"
          value={title}
          placeholder="Please give your post a title."
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      <label>
        Content:
        <textarea
          value={content}
          rows="5"
          placeholder="Tell us what's your story."
          onChange={(e) => setContent(e.target.value)}
        />
      </label>
      <label for="img">
        Select cover image:
        <input
          type="file"
          id="img-selector"
          name="img-selector"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
              alert(
                "File size should be less than 5Mb. " +
                  `This file is ${
                    Math.round((10 * file.size) / 1024 / 1024) / 10
                  }Mb`
              );
              return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
              setSelectedFile(reader.result);
            };
            reader.readAsDataURL(file);
          }}
        />
      </label>
      <input type="submit" value="Send" />
    </form>
  );
};

export default Form;
