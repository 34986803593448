import React, { useState } from "react";
import Posts from "./components/posts";
import Form from "./components/form";
import "./App.css";
import "katex/dist/katex.min.css";

const App = () => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  return (
    <div className="App">
      <header className="App-header"></header>
      <div
        className="controller"
        onClick={() => {
          scrolledToBottom
            ? window.scrollTo({ top: 0, behavior: "smooth" })
            : window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
          setScrolledToBottom(!scrolledToBottom);
        }}
      >
        <i className={scrolledToBottom ? "arrow up" : "arrow down"} />
      </div>
      <div className="container">
        <Posts />
        <Form />
      </div>
      <div className="bottom">
        <p>
          This website is used to demonstrate the serverless capabilities of
          Cloudflare workers and Pages
        </p>
        <p>@2021 Yujie Wang</p>
      </div>
    </div>
  );
};

export default App;
