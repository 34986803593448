import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";

// wrapper for reactMarkdown to provide support for katex
const Markdown = ({ children }) => {
  console.log("children" + children);
  return (
    <ReactMarkdown
      children={children}
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        img: ({ node, ...props }) => (
          <div className="image-container">
            <img {...props} />
          </div>
        ),
      }}
    />
  );
};

export { Markdown };
