import React, { useState } from "react";
import { Markdown } from "./markdown.js";
import "./post.css";

const base = process.env.REACT_APP_BASE;

const Post = (_post) => {
  const [post, setPost] = useState(_post.post);
  console.log(post.content);
  const dateStr = new Date(post.createdAt).toISOString().split("T")[0];
  const isLongContent = post.content.length > 400;
  const [expand, setExpand] = useState(false);
  const [expandImage, setExpandImage] = useState(false);

  const setLike = (id) => {
    fetch(base + "/post/like", {
      method: "POST",
      body: JSON.stringify({ postId: id }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setPost(data.post);
      });
  };

  return (
    <div className="post">
      <div className="header">
        <h2>{post.title}</h2>
        <div className="detail">{post.username + " | " + dateStr}</div>
      </div>
      <div className="image-container">
        {post.image ? (
          <img
            alt=""
            onClick={() => setExpandImage(!expandImage)}
            className={
              expandImage || !post.content || expand ? "full" : "small"
            }
            src={post.image}
          />
        ) : (
          <></>
        )}
      </div>
      {post.content ? (
        isLongContent ? (
          <div className="content">
            <Markdown>
              {!expand ? post.content.slice(0, 300) + "..." : post.content}
            </Markdown>
            <div
              style={{ textAlign: "center" }}
              onClick={() => setExpand(!expand)}
            >
              <i className={"arrow " + (expand ? "up" : "down")}></i>
            </div>
          </div>
        ) : (
          <div className="content">
            <Markdown>{post.content}</Markdown>
          </div>
        )
      ) : (
        <></>
      )}
      <div className="like" onClick={() => setLike(post.id)}>
        {post.like ? post.like : ""}
      </div>
    </div>
  );
};

export { Post };
